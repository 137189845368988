import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "webinar script",
  categories: [templateCategory.video, templateCategory.marketing],
  defaultWordsCount: 800,
  titleName: "title",
});

export const webinarScriptTemplate = {
  ...createdTemplate,
  contentType: "webinar script",
  contentTypeLabel: "webinar script",
  helpText:
    "Provide a brief description of the webinar, its objectives, and the topics to be covered",
  metaTitle: "Free Webinar Script Generator",
  metaDescription:
    "Improve your webinars with our free script generator. Craft engaging, professional webinar scripts that captivate your audience and drive results.",
  cardDescription:
    "Create engaging, professional webinar scripts in minutes.",
  shortDescription:
    "Create a webinar script based on the provided description and objectives",
  caption:
    "Deliver an effective and memorable webinar experience with a well-crafted script",
  excludedFields: ["toneOfVoice", "editorialImage", "startFromOutline"],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: `Enter or generate a title for your webinar script`,
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable title for a webinar script based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      required: true,
      description: " ",
      placeholder: `- [Webinar objectives] Example: Every participant will learn how to create a blog post that ranks on Google using GenerateForMe.com's large offering. \n  - [Topics to be covered] Example: How is GenerateForMe.com outperforming from other AI tools and example case studies of using GenerateForMe.com AI for business success\n  - [Additional Details]`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const writingStyle = post.writingStyle;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;

        let prompt =
          "Create a script for a webinar with the following information";

        if (description) {
          prompt += `Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should be made understandable to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }
        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
