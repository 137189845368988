import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  isPartialContentDefined,
  isTitleDefined,
} from "../validation";

export const jobDescriptionTemplate: TemplateType = {
  id: "job-description-251dy",
  value: "job-description-251dy",
  label: "job-description",
  slug: "job-description",
  categories: [templateCategory.socialMedia, templateCategory.marketing],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "job description",
  title: "Job Description",
  metaTitle: "Free Job Description Writer",
  metaDescription:
    "Streamline your hiring process with our free job description writer. Create clear, concise and optimized job descriptions to attract top talent.",
  defaultWordsCount: 800,
  contentTypeLabel: "job description",
  helpText: "Create a clear and comprehensive job description",
  defaultCollectionName: "Job Description collection",
  collectionNamePlaceholder:
    "Enter a name for your Job Descriptions collection",
  postNamePlaceholder: "Enter a title for your Job Description",
  defaultPostName: "Untitled Job Description",
  toGeneratorPrompt: "Need to generate more job descriptions?",
  cardDescription:
    "Craft precise, engaging job descriptions to attract the right candidates.",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  excludedFields: ["writingStyle", "editorialImage", "startFromOutline"],
  fields: {
    title: {
      label: "Job Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Eg: Software Engineer, Marketing Manager",
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isPartialContentDefined(post));
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a compelling title for an job description";

        if (description) {
          prompt += `, some info about the job: ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }
        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the job description: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "Job Info",
      fieldName: "description",
      required: true,
      placeholder: `[Company Name]
[Responsibilities]: Example: Develop software solutions, Manage marketing campaigns
[Requirements]: Example: 3+ years of experience, Bachelors degree in Marketing`,
      description: " ",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Formal, Excitement",
    },
    content: {
      label: "Job Description Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(isTitleDefined(post) || isDescriptionDefined(post));
      },
      calculateCost: (config) => config.wordsCount || 800,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const title = post.title;
        const targetAudience = post.targetAudience;

        let prompt =
          "Write a comprehensive job description based on the provided information";

        if (description) {
          prompt += `. some info about the job: ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (toneOfVoice) {
          prompt += ` using a ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The job description should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". Please ensure the content is well-structured and engaging.";

        return prompt;
      },
    },
    targetAudience: {
      label: "Target Audience",
      fieldName: "targetAudience",
      optional: true,
      placeholder: "Content writer, Developers, Students etc",
    },
  },
};
