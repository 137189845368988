import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  isKeywordsDefined,
  isPartialContentDefined,
  isPurposeDefined,
  isTitleDefined,
} from "../validation";

export const youtubeVideoScriptTemplate: TemplateType = {
  id: "youtube-video-script-251dy",
  value: "youtube-video-script-251dy",
  label: "youtube-video-script",
  slug: "youtube-video-script",
  categories: [
    templateCategory.socialMedia,
    templateCategory.video,
    templateCategory.entertainment,
  ],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "youtube Video Script",
  title: "Youtube Video Script",
  defaultWordsCount: 500,
  metaTitle: "Free Youtube Video Script Writer",
  metaDescription:
    "Elevate your YouTube content with our free video script writer. Generate engaging, SEO-optimized scripts to captivate your audience instantly.",
  contentTypeLabel: "youtube Video Script",
  helpText: "Create an engaging script for your YouTube video",
  defaultCollectionName: "Youtube Video Script collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your Youtube Video Script",
  defaultPostName: "Untitled Youtube Video Script",
  toGeneratorPrompt: "Need to generate more YouTube video script content?",
  cardDescription:
    "Generate engaging scripts that captivate audiences and enhance your video SEO.",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  requiredFields: ["title"],
  addtionalFields: [],
  excludedFields: ["toneOfVoice", "editorialImage", "startFromOutline"],
  fields: {
    title: {
      label: "Video Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Eg: 10 Tips to Boost Your Productivity",
      onValidate: (post) => {
        return !!(
          isDescriptionDefined(post) ||
          isPartialContentDefined(post) ||
          isKeywordsDefined(post)
        );
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a compelling title for youtube video";

        if (description) {
          prompt += ` with the topic of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the script: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "What's your video about?",
      fieldName: "description",
      required: true,
      placeholder: `Example: A tips-and-tricks video on how to boost your productivity using GenerateForMe.com's writing tools`,
      description: " ",
    },
    writingStyle: {
      label: "Video Style",
      fieldName: "writingStyle",
      placeholder: "Eg: How-to Tutorial, Review, Vlog, Unboxing, Gaming etc",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(
          isPurposeDefined(post) ||
          isDescriptionDefined(post) ||
          isTitleDefined(post)
        );
      },
      calculateCost: (config) => config.wordsCount || 500,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const writingStyle = post.writingStyle;
        const title = post.title;
        const targetAudience = post.targetAudience;
        const keywords = post.keywords;

        let prompt =
          "Write a script for a YouTube video based on the provided information";

        if (description) {
          prompt += `Some info on the video: ${description}`;
        }

        if (writingStyle) {
          prompt += `, style of the video: ${writingStyle}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience:: ${targetAudience}`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The script should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". Please ensure the content is engaging and make sure to include intro, outro as well.";

        return prompt;
      },
    },
  },
};
