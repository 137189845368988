import MuiTooltip, { TooltipProps } from "@mui/material/Tooltip";
import { getStyleProps } from "modules/themes/styleUtils";

export const Tooltip = (props: TooltipProps) => {
  return (
    <MuiTooltip
      placement="top"
      enterTouchDelay={50}
      {...props}
      {...getStyleProps(props)}
    >
      {props.children}
    </MuiTooltip>
  );
};
