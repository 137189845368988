import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "clickbait title",
  categories: [templateCategory.blog, templateCategory.email],
  defaultWordsCount: 1000,
  titleName: "title",
});

export const clickBaitTemplate = {
  ...createdTemplate,
  contentType: "clickbait title",
  contentTypeLabel: "clickbait title",
  metaTitle: "Free Clickbait Title Generator",
  metaDescription:
    "Generate irresistible clickbait titles for free! Boost your blog's traffic, engagement, and SEO ranking with our user-friendly title generator.",
  helpText:
    "Provide an overview of the project or service you are proposing, including the objectives, timeline, and any other relevant information",
  cardDescription:
    "Drive traffic and engagement effortlessly with catchy headlines.",
  shortDescription:
    "Drive traffic and engagement effortlessly with catchy headlines.",
  caption: "Drive traffic and engagement effortlessly with catchy headlines.",
  excludedFields: [
    "writingStyle",
    "toneOfVoice",
    "editorialImage",
    "startFromOutline",
    "targetAudience",
  ],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    description: {
      label: "Description",
      fieldName: "description",
      required: true,
      description: " ",
      placeholder: `Example: How to Write a Blog Post: A Step-by-Step Guide`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const language = post.language;

        let prompt =
          "Give me a list of click bait titles based on the following text: ";

        if (description) {
          prompt += `Text: ${description}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
