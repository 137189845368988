import { templateCategory, TemplateType } from "../types";

import {
  canGeneratePostContent,
  isDescriptionDefined,
  createOnInvalidFunc,
  isPartialContentDefined,
} from "../validation";

export const specialDiscountPromotionTemplate: TemplateType = {
  id: "special-discount-promotion",
  value: "special_discount_promotion",
  label: "Special Discount Promotion",
  slug: "special-discount-promotion",
  categories: [
    templateCategory.ecommerce,
    templateCategory.marketing,
    templateCategory.socialMedia,
  ],
  tags: ["Discount", "Promotion", "Offer", "Sales", "Deals"],
  contentType: "special discount promotion",
  title: "Special Discount Promotion",
  contentTypeLabel: "special discount promotion",
  metaTitle: "Free Special Discount Promotion Generator",
  metaDescription:
    "Boost your sales with our Free Special Discount Promotion Generator. Create irresistible deals that attract customers and boost your bottom line.",
  defaultWordsCount: 300,
  helpText: "Provide details about the discount, product, and target audience",
  defaultCollectionName: "Special Discount Promotions",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your discount promotion",
  defaultPostName: "Untitled Promotion",
  toGeneratorPrompt: "Need to create more special discount promotions?",
  cardDescription:
    "Create enticing deals and attract more customers instantly.",
  shortDescription: "All fields are <strong>optional</strong>",
  caption:
    "You can edit the generated promotion content to fit your specific needs.",
  requiredFields: ["title"],
  excludedFields: [
    "keywords",
    "writingStyle",
    "editorialImage",
    "startFromOutline",
  ],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      placeholder: "Enter or generate a title for the promotion",
      calculateCost: () => 50,
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isPartialContentDefined(post));
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        let prompt =
          "Generate a captivating title for a special discount promotion";

        if (targetAudience) {
          prompt += `, targeting the audience: ${targetAudience}`;
        }

        if (description) {
          prompt += `. The promotion description is: ${description}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Description",
      fieldName: "description",
      required: true,
      description: "Enter a brief description of the promotion",
      placeholder: `- [Product discount details]
- [Any instructions]`,
    },
    targetAudience: {
      label: "Target Audience",
      fieldName: "targetAudience",
      optional: true,
      placeholder: "Home owners, Students, Office workers etc",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Excitement and Enthusiasm, Urgency and Scarcity",
    },
    content: {
      label: "Content",
      fieldName: "content",
      onValidate: (post) => {
        return canGeneratePostContent(post);
      },
      calculateCost: (config) => config.wordsCount || 300,
      onInValid: createOnInvalidFunc(
        "A title or some partial description is required to generate promotion content"
      ),
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;

        const wordsCount = post.wordsCount;

        let prompt = "Create a special discount promotion content";

        if (description) {
          prompt += ` with the product discount details of: ${description}`;
        }

        if (targetAudience) {
          prompt += `, targeting the audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        prompt += ".";

        if (wordsCount) {
          prompt += ` Limit the content to ${wordsCount} words.`;
        }

        return prompt;
      },
    },
  },
};
