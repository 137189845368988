import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";
const createdTemplate = templateCreator({
  value: "restaurant review",
  categories: [templateCategory.socialMedia, templateCategory.custom],
  defaultWordsCount: 500,
  titleName: "title",
});

export const restaurantReviewTemplate = {
  ...createdTemplate,
  contentType: "restaurant review",
  contentTypeLabel: "restaurant review",
  metaTitle: "Free Restaurant Review Writer",
  metaDescription:
    "Boost your restaurant's online presence with our free review writer. Generate engaging, SEO-optimized reviews and attract more customers today!",
  helpText:
    "Provide the name of the restaurant, location, type of cuisine, and any specific aspects you want to be covered in the review",
  cardDescription:
    "Generate compelling and SEO-friendly reviews to attract more customers.",
  shortDescription:
    "Create a compelling restaurant review based on the provided details",
  caption:
    "Share your dining experience with a well-written restaurant review that captivates readers and offers valuable insights",
  excludedFields: [
    "writingStyle",
    "toneOfVoice",
    "editorialImage",
    "startFromOutline",
  ],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title for your restaurant review",
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;
        let prompt = `Generate a suitable title for a restaurant review based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      required: true,
      description: " ",
      placeholder: `- [Name of the Restaurant]
- [Location]
- [Type of Cuisine]
- [Specific Aspects to Cover]`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;
        let prompt =
          "Create a compelling restaurant review based on the following information";

        if (description) {
          prompt += `. Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should be suitable for the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
