import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "competitor analysis",
  categories: [templateCategory.marketing],
  defaultWordsCount: 600,
  titleName: "title",
});

export const competitorAnalysisTemplate = {
  ...createdTemplate,
  contentType: "competitor analysis",
  contentTypeLabel: "competitor analysis",
  metaTitle: "Free Competitor Analysis Generator",
  metaDescription:
    "Uncover insights with our free competitor analysis generator. Gain an edge over your rivals by understanding their strategies and performance.",
  helpText:
    "Provide details about your company, target audience, and key competitors",
  cardDescription:
    "Gain insights, leverage strengths, and address weaknesses to outperform your rivals in the market.",
  shortDescription:
    "Create a competitor analysis based on the provided company, target audience, and competitor details",
  caption:
    "Stay ahead of the competition by understanding their strategies and uncovering untapped market opportunities",
  excludedFields: [
    "writingStyle",
    "toneOfVoice",
    "editorialImage",
    "startFromOutline",
  ],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: `Enter or generate a title for your competitor analysis`,
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable title for a competitor analysis based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder: `- [Your Company] Example: GenerateForMe.com is an all-in-one AI content platform offering all features that its competitors offer but with cheaper pricing and more reliable service  \n- [Key Competitors] Example: ChatGPT where it's only an AI chatbot and covers only one specifc part of the content creation process`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 600,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;

        let prompt =
          "Perform a competitor analysis based on the following details";

        if (description) {
          prompt += ` Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should be appealing to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
