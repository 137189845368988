import { templateCategory, TemplateType } from "../types";
import { Post } from "client-server-shared/types/types";

import {
  isDescriptionDefined,
  isPartialContentDefined,
  isPurposeDefined,
} from "../validation";

export const instagramPostTemplate: TemplateType = {
  id: "instagram-caption-251dy",
  value: "instagram-caption-251dy",
  label: "instagram-caption",
  slug: "instagram-caption",
  categories: [templateCategory.socialMedia],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "instagram Caption",
  title: "Instagram Caption",
  defaultWordsCount: 250,
  metaTitle: "Free Instagram Caption Generator",
  metaDescription:
    "Boost your Instagram engagement with our free caption generator. Create compelling, catchy captions to attract more likes and followers.",
  contentTypeLabel: "instagram post",
  helpText: "Choose at least one post topic to begin",
  defaultCollectionName: "Instagram Post collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your Instagram Post",
  defaultPostName: "Untitled Instagram Post",
  toGeneratorPrompt: "Need to generate more instagram posts?",
  cardDescription:
    "Create compelling captions that attract likes, comments, and followers!",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  addtionalFields: [],
  excludedFields: [
    "targetAudience",
    "keywords",
    "writingStyle",
    "editorialImage",
    "startFromOutline",
  ],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title",
      onValidate: (post) => {
        return !!(
          isDescriptionDefined(post) ||
          isPartialContentDefined(post) ||
          isPurposeDefined(post)
        );
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a compelling title for an instagram post";

        if (description) {
          prompt += ` with the topic of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }
        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the post: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "Post Topic",
      fieldName: "description",
      required: true,

      placeholder: `Going on a vacation, starting a new job, or just want to share your thoughts?`,
      description: " ",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Friendly, formal",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(isPurposeDefined(post) || isDescriptionDefined(post));
      },
      calculateCost: (config) => config.wordsCount || 250,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const purpose = post.purpose;

        let prompt =
          "Generate an instagram post caption based on the provided information";

        if (description) {
          prompt += `. Post Topic: ${description}`;
        }

        if (toneOfVoice) {
          prompt += ` using a ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The post should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". Please ensure the content is well-structured and engaging.";

        return prompt;
      },
    },
  },
};
