import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  isKeywordsDefined,
  isPartialContentDefined,
  isTitleDefined,
} from "../validation";

export const landingPageTemplate: TemplateType = {
  id: "landing-page-251dy",
  value: "landing-page-251dy",
  label: "landing-page",
  slug: "landing-page",
  categories: [templateCategory.marketing, templateCategory.websiteCopy],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "website landing page",
  title: "Website Landing Page",
  metaTitle: "Free Website Landing Page Generator",
  metaDescription:
    "Easily create stunning landing pages for your website with our free generator. Boost conversions and enhance user experience effortlessly.",
  defaultWordsCount: 300,
  contentTypeLabel: "website landing page",
  helpText: "Create compelling content for your website's landing page",
  defaultCollectionName: "Landing Page collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a name for your Landing Page",
  defaultPostName: "Untitled Landing Page",
  toGeneratorPrompt: "Need to generate more Landing page content?",
  cardDescription:
    "Create Website Pages in Minutes!",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  addtionalFields: [],
  excludedFields: [
    "toneOfVoice",
    "editorialImage",
    "startFromOutline",
    "writingStyle",
  ],
  fields: {
    title: {
      label: "Website Name",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a website name",
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isPartialContentDefined(post));
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a website name for a landing page:";

        if (description) {
          prompt += `, with the description of: ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }
        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content of the landing page: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "Description",
      fieldName: "description",
      placeholder: "Example: GenerateForMe.com is an all-in-one content creation platform, create a full-fledged article from a simple idea within minites",
      description: "What does your website do?",
      required: true,
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Excitement and Enthusiasm, Urgency and Scarcity",
    },
    content: {
      label: "Landing Page Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(
          isTitleDefined(post) ||
          isDescriptionDefined(post) ||
          isKeywordsDefined(post)
        );
      },
      calculateCost: (config) => config.wordsCount || 250,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const keywords = post.keywords;
        const language = post.language;
        const title = post.title;
        const targetAudience = post.targetAudience;

        let prompt =
          "Write engaging content for a website landing page based on the provided information";

        if (description) {
          prompt += `. website description: ${description}`;
        }

        if (toneOfVoice) {
          prompt += `, using a ${toneOfVoice} tone of voice`;
        }

        if (targetAudience) {
          prompt += `, resonates with the target audience: ${targetAudience}`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The landing page should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". Please ensure it builds excitement, anticipation and it's shareable and make sure to include headline, subheadline, call to actions";

        return prompt;
      },
    },
  },
};
