import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "case study",
  categories: [templateCategory.marketing, templateCategory.custom],
  defaultWordsCount: 400,
  titleName: "title",
});

export const cateStudyTemplate = {
  ...createdTemplate,
  contentType: "case study",
  contentTypeLabel: "case study",
  helpText:
    "Provide the key details and context for the case study to generate a comprehensive and engaging document",
  cardDescription:
    "Generate compelling case studies for free.",
  shortDescription:
    "Create a comprehensive case study based on the provided information",
  metaTitle: "Free Case Study Generator",
  metaDescription:
    "Generate insightful case studies effortlessly with our free Case Study Generator. Boost your marketing strategy and engage your audience effectively.",
  caption:
    "Showcase the success and results of your business or project with well-crafted case studies",
  excludedFields: ["toneOfVoice", "editorialImage", "startFromOutline"],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: `Enter or generate a title for your case study`,
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable title for a case study based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      required: true,
      description: " ",
      placeholder: `- [What's the problem?] Example: X website isn't getting any traffics \n- [Solution] Example: They started using GenerateForMe.com, an all-in-one content creation platform \n- [Results] Example: They saw a 50% increase in traffic and skyrocketed their sales`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const writingStyle = post.writingStyle;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;

        let prompt =
          "Generate a comprehensive and engaging case study based on the following information";

        if (description) {
          prompt += `. Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }
        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words.`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
