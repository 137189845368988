import { dashboardRouteConfig } from "client-server-shared/config/routes";
import {
  Container,
  ResponsiveText,
  ResponsiveTextProps,
  headlineSizeConfig,
} from "../../marketing-pages/front-page/shared";
import { MainBackgroundWrapper, headerHeight } from "../shared";
import {
  PrimaryCallToAction,
  PrimaryCallToActionProps,
} from "../../marketing-pages/call-to-action";

import React from "react";
import { Description, Typography } from "components/ui/Typography";
import { graidentTextStyle } from "./shared";
import TypeWriterWrapper from "components/type-writer";
import { Box, BoxProps } from "components/ui/box";

interface Props {
  primaryText: React.ReactNode;
  secondaryText: React.ReactNode;
  slides: string[];
  callToAction?: React.ReactNode;
  slot?: {
    main?: React.ReactNode;
    secondary?: React.ReactNode;
    callToAction?: React.ReactNode;
  };
  wrapperProps?: BoxProps;
  innerWrapperProps?: BoxProps;
  primaryTextProps?: ResponsiveTextProps;
  secondaryTextProps?: ResponsiveTextProps;
  textPlacement?: "secondary-first" | "slides-first";
  callToActionProps?: PrimaryCallToActionProps;
  dsiableBackground?: boolean;
}

export const PrimaryHero = React.memo(
  ({
    primaryText,
    secondaryText,
    slides = [],
    slot,
    dsiableBackground = false,
    wrapperProps = {},
    innerWrapperProps = {},
    primaryTextProps = {},
    callToActionProps = {},
    secondaryTextProps = {},
  }: Props) => {
    return (
      <Box
        className="front-page-bg"
        component="section"
        dsiableBackground={dsiableBackground}
        {...wrapperProps}
        sx={{
          textAlign: "center",
          position: "relative",
          padding: "30px 0 0 0",

          ...(wrapperProps.sx || {}),
        }}
      >
        <Container
          {...innerWrapperProps}
          sx={{
            marginTop: {
              xs: `calc((100vh - ${headerHeight}px) / 8)`,
              sm: `calc((100vh - ${headerHeight}px) / 8)`,
              md: `calc((100vh - ${headerHeight}px) / 8)`,
              xl: `calc((100vh - ${headerHeight}px) / 8)`,
            },
            ...(innerWrapperProps.sx || {}),
          }}
        >
          <ResponsiveText
            variant="h1"
            textAlign="center"
            fontSize={45}
            {...primaryTextProps}
            sx={{
              color: "text.white",
              margin: "auto",
              fontWeight: 500,
              maxWidth: "700px",
              textAlign: "center",
              width: {
                xs: "100%",
                sm: `100%`,
                md: `100%`,
                xl: `100%`,
                lg: "100%",
              },
              ...(primaryTextProps.sx || {}),
            }}
          >
            {primaryText}
          </ResponsiveText>
          <ResponsiveText
            color="text.white"
            variant="body2"
            fontSize={18}
            textAlign={"center"}
            sx={{
              marginTop: "24px",
              fontWeight: 400,
            }}
          >
            {secondaryText}
          </ResponsiveText>
          {slot?.main ? slot.main : null}
        </Container>
        {slot?.secondary ? slot.secondary : null}
      </Box>
    );
  }
);
