import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "privacy policy",
  categories: [templateCategory.websiteCopy, templateCategory.ecommerce],
  defaultWordsCount: 800,
  titleName: "title",
});

export const privacyPolicyTemplate = {
  ...createdTemplate,
  contentType: "privacy policy",
  contentTypeLabel: "privacy policy",
  metaTitle: "Free Privacy Policy Generator",
  metaDescription:
    "Create a comprehensive privacy policy for your website with our free generator. Ensure legal compliance and build trust with your visitors.",
  helpText:
    "Provide the main elements and requirements for the privacy policy you want to create",
  cardDescription:
    "Generate a free, comprehensive privacy policy for your website or app in minutes.",
  shortDescription:
    "Create a detailed privacy policy based on the provided information",
  caption:
    "Generate a basic privacy policy for your website or app. Please review and consult with legal professionals before using to ensure compliance with applicable laws and regulations.",
  excludedFields: [
    "writingStyle",
    "toneOfVoice",
    "editorialImage",
    "startFromOutline",
  ],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title for your privacy policy",
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;
        let prompt = `Generate a suitable title for a privacy policy based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder: `- [Main Elements] Example: We don't use cookie nor we use and sell users' data \n- [Requirements] Example: Highlight what choices users have regarding their data and how GenerateForMe.com is taking security measure protecting the interests of our users \n- [Other Relevant Information] Example: You may contact us at any time for any questions regarding our privacy policy`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 1500,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;
        let prompt =
          "Create a comprehensive privacy policy with the following information";

        if (description) {
          prompt += `Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should be tailored to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
