import { isCn } from "client-server-shared/constants";
import type { Post, PostConfig } from "client-server-shared/types/types";

let templateCategory = {
  all: "All",
  blog: "Blog",
  custom: "Custom",
  email: "Email",
  socialMedia: "Social Media",
  entertainment: "Entertainment",
  websiteCopy: "Website Copy",
  ecommerce: "Ecommerce",
  video: "Video",
  marketing: "Marketing",
};

if (isCn()) {
  templateCategory = {
    all: "全部",
    blog: "博客",
    custom: "自定义",
    email: "电子邮件",
    socialMedia: "社交媒体",
    entertainment: "娱乐",
    websiteCopy: "网站文案",
    ecommerce: "电子商务",
    video: "视频",
    marketing: "营销",
  };
}

export { templateCategory };

export const templateCategories = Object.values(templateCategory);

export interface GeneratePostConfig extends PostConfig {
  title?: string;
  content?: string;
  beforeContent?: string;
  afterContent?: string;
}

export enum TemplateFieldType {
  TextInput = "text-input",
  TextArea = "text-area",
  InfoBase = "info-base",
}

export interface TemplateField {
  label: string;
  fieldName: string;
  description?: string;
  placeholder?: string; // Write me a song
  onValidate?: (postConfig: GeneratePostConfig) => boolean;
  calculateCost?: (postConfig: GeneratePostConfig) => number;
  onInValid?: OnInValid;
  buildPrompt?: (
    postConfig: GeneratePostConfig,
    getInfobaseContentBySourceIds: (ids: string[]) => Promise<string>
  ) => string | Promise<string>;
  buildSystemPrompt?: (
    postConfig: GeneratePostConfig,
    getInfobaseContentBySourceIds: (ids: string[]) => Promise<string>
  ) => string | Promise<string>;
  getAIConfig: (config?: { model?: string }) => Record<string, any>;
  type?: TemplateFieldType;
  optional?: boolean;
  required?: boolean;
  autoCompleteSuggestions?: { value: string; label: string }[];
  wordsLimit?: number;
  info?: string;
}

export interface CustomTemplateField extends TemplateField {
  type: TemplateFieldType;
}

export interface TemplateType {
  id: string;
  value: string;
  label: string;
  categories: string[];
  tags: string[];
  contentType: string;
  tokenCount?: number;
  excludedFields?: string[];
  contentTypeLabel: string;
  title: string;
  freeLimit?: number;
  shortSummary: string;
  howToPath?: string;
  asTool?: boolean;
  shortDescription: string;
  longDescription: string;
  icon?: any;
  fields: Record<string, TemplateField>;
  requiredOneOf: string[];
  newStructure?: boolean;
}

export type OnValidate = (post: Post) => boolean;
export type OnInValid = ({
  post,
  notificationApi,
}: {
  post: Post;
  notificationApi: any;
}) => void;

export interface GenerateOptions {
  templateId: string;
  fields: GeneratePostConfig;
}

export interface CustomGenerateOptions {
  templateId: string;
  fields: GeneratePostConfig & {
    command: string;
  };
}
