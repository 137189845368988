import { templateCategory, TemplateType } from "../types";

import {
  canGeneratePostContent,
  isDescriptionDefined,
  createOnInvalidFunc,
  isKeywordsDefined,
  isPartialContentDefined,
} from "../validation";

export const productDescriptionTemplate: TemplateType = {
  id: "product-description-TeHqM",
  value: "product-description-TeHqM",
  label: "Product Description",
  slug: "product-description",
  categories: [templateCategory.ecommerce],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "product description",
  title: "Product Description",
  metaTitle: "Free Product Description Generator",
  metaDescription:
    "Boost your product listings with our free product description generator. Create compelling, SEO-friendly descriptions to attract more customers.",
  contentTypeLabel: "product description",
  helpText: "Choose at least one keyword, description, or title to begin",
  defaultCollectionName: "Product Description collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a name for your product",
  defaultPostName: "Untitled Product Description",
  toGeneratorPrompt: "Need to generate more product descriptions?",
  defaultWordsCount: 150,
  cardDescription:
    "Create compelling, SEO-optimized descriptions to attract more customers.",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  requiredFields: ["title"],
  excludedFields: ["editorialImage", "startFromOutline", "writingStyle"],
  fields: {
    title: {
      label: "Product Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a complelling product title",
      onValidate: (post) => {
        return !!(
          isDescriptionDefined(post) ||
          isPartialContentDefined(post) ||
          isKeywordsDefined(post)
        );
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a complelling title for a product";

        if (description) {
          prompt += ` with the description or features of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the product description: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    writingStyle: {
      label: "Writing Style",
      fieldName: "writingStyle",
      optional: true,
      placeholder: "Eg: Informative, conversational",
    },
    description: {
      label: "Product description",
      fieldName: "description",
      required: true,
      description: "Characteristics/features of the product",
      placeholder: `- Name: GenerateForMe.com - an all-in-one content creation platform\n- More affordable compared to other AI tools\n- Low error rate\n- The generated content gets indexed by Google overnight`,
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Examples: Excited, Scarcity etc",
    },
    targetAudience: {
      label: "Target audience",
      fieldName: "targetAudience",
      optional: true,
      placeholder: "Hikers, Chefs, Photographer etc",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return canGeneratePostContent(post);
      },
      calculateCost: (config) => config.wordsCount || 250,
      onInValid: createOnInvalidFunc(
        "Either a product name or some product description is required to generate a product description"
      ),
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const keywords = post.keywords;
        const title = post.title;
        let prompt = "Write an engaging product description";

        if (description) {
          prompt += `highlight the following product description or key benefits: ${description} with unique selling points`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, using a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, with a ${toneOfVoice} tone of voice`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, incorporating the following keywords: ${keywords.join(
            ", "
          )}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The product description should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". Please ensure that the description is SEO-optimized and includes relevant keywords to improve search engine rankings";

        return prompt;
      },
    },
  },
};
