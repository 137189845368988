import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "real estate listing",
  categories: [templateCategory.custom],
  defaultWordsCount: 500,
  titleName: "title",
});

export const realEstateListingTemplate = {
  ...createdTemplate,
  contentType: "real estate listing",
  contentTypeLabel: "real estate listing",
  metaTitle: "Free Real Estate Listing Generator",
  metaDescription:
    "Boost your real estate business with our free listing generator. Create compelling property listings to attract potential buyers effortlessly.",
  helpText:
    "Provide details about the property, including location, features, and any unique selling points",
  cardDescription:
    " Create compelling listings that attract buyers and close deals faster.",
  shortDescription:
    "Create a captivating real estate listing based on the provided property details",
  caption:
    "Showcase your property with a well-crafted real estate listing that highlights its features and appeals to potential buyers or renters",
  excludedFields: [
    "writingStyle",
    "toneOfVoice",
    "editorialImage",
    "startFromOutline",
  ],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: `Enter or generate a title for your real estate listing`,
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable title for a real estate listing based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder: `- [Location] Example: Two bedroom beachhouse in Chania, Greece \n- [Features] Example: A private 50m2 swimming pool, a large garden, and a spacious living room\n- [Unique Selling Points] Example: Right next to beach, close to the city center, and a 10-minute drive from the airport`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;

        let prompt =
          "Create a captivating real estate listing for a property with the following information";

        if (description) {
          prompt += `Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should be appealing to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
