import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "facebook-ads",
  categories: [templateCategory.ecommerce, templateCategory.marketing],
  defaultWordsCount: 800,
  titleName: "title",
});

export const facebookAdsTemplate = {
  ...createdTemplate,
  contentType: "facebook ads",
  contentTypeLabel: "facebook ads",
  helpText:
    "Provide the destination, trip duration, and any specific points of interest or activities you want to include in the itinerary",
  cardDescription: "Create engaging, high-converting ads in just a few clicks.",
  shortDescription: "Create Attention-Grabbing Facebook Ads that Deliver!",
  caption: "Create Attention-Grabbing Facebook Ads that Deliver!",
  metaTitle: "Free Facebook Ads Generator",
  metaDescription:
    "Boost your marketing with our Free Facebook Ads Generator. Create compelling ad content to reach your target audience and drive engagement.",
  excludedFields: [
    "writingStyle",
    "editorialImage",
    "startFromOutline",
    "targetAudience",
  ],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title for your travel itinerary",
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable headline for a facebook ads based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Product description",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder: `Example: GenerateForMe.com is an ai writer that helps you write blogs, emails and ads in seconds.`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      buildSystemPrompt: () => {
        let prompt = `Your task is to generate compelling facebook ads based on the information provided by the user. Your response will be uploaded straight onto facebook. You will reply the generated content only without anything in your response.

        Here are some requirements for the generated content:

        1. Primary text should be between 80-125 characters.
        2. Headline should be between 25-40 characters.
        3. Description should be less than 25 characters.

        Examples:

        Example 1: 

        Primary text: Peace of mind for pet owners! Our new pet hotel, run by experienced vets, guarantees your furry friend is in safe hands while you're away. Leave your worries behind and trust our caring team to always be there for your pet's needs.

        Headline: Peace of Mind for Pet Parents: Trust Your Furry Friends with Our Expert Vet Team!

        Description: Pet Boarding at Vet Clinic: "Pampered Paws!"

        Example 2: 

        Primary text: Support a young entrepreneur's dream - taste the passion and dedication in every cup at Coffee Beans Cafe!

        Headline: Indulge in guilt-free treats at our new vegan coffee shop!

        Description: Coffee Shop Opening: "Sip & Savor!"


        Your reply will be uploaded straight onto facebook. You will reply the generated content only without anything in your response.
        `;
        return prompt;
      },
      calculateCost: (config) => config.wordsCount || 1000,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const toneOfVoice = post.toneOfVoice;

        const title = post.title;
        let prompt =
          "Create a compelling facebook ads based on the following information";

        if (description) {
          prompt += `. Product/Service description: "${description}:`;
        }

        if (toneOfVoice) {
          prompt += `, should adapt to the following tone of voice: "${toneOfVoice}"`;
        }

        if (language && language !== "English") {
          prompt += `, in ${language} language.`;
        }

        prompt += `
        Your generated content will be uploaded straight to facebook, ensure to adhere the following characters requirements:
        
        1. Primary text should be between 80-125 characters.
        2. Headline should be between 25-40 characters.
        3. Description should be less than 25 characters.

        Reply the generated content only without anything else in your response.
        `;

        prompt += ".";

        return prompt;
      },
    },
  },
};
