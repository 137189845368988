// @ts-nocheck
import keyBy from "lodash/keyBy";
import { blogTemplate } from "./blog-post";
import { customTemplate } from "./custom";
import { instagramPostTemplate } from "./instagram-post";
import { instagramBioTemplate } from "./instagram-bio";
import { facebookPostTemplate } from "./facebook-post";
import { twitterPostTemplate } from "./twitter-post";
import { productDescriptionTemplate } from "./product-description";
import { productReviewTemplate } from "./product-review";
import { lyricTemplate } from "./lyric";
import { productLauncherTemplate } from "./product-launcher";
import { eventPromotionTemplate } from "./event-promotion";
import { customerTestimonialTemplate } from "./customer-testimonial";
import { specialDiscountPromotionTemplate } from "./special-discount-promotion";
import { welcomeConfirmationEmailTemplate } from "./welcome-confirmation";
import { jobDescriptionTemplate } from "./job-description";
import { aboutUsTemplate } from "./about-us";
import { landingPageTemplate } from "./landing-page";
import { youtubeVideoScriptTemplate } from "./youtube-video-script";
import { tiktokVideoScriptTemplate } from "./tiktok-video-script";
import { productPromotionTemplate } from "./product-promotion";
import { recurringEmailTemplate } from "./recurring-email-news-letter";
import { shortStoryTemplate } from "./short-story";
import { reviewResponderTemplate } from "./reviewer-responder";
import { benefitStatementTemplate } from "./benefit-statement";
import { betterExplainTemplate } from "./better-explain";
import { coldEmailTemplate } from "./cold-email";
import { socialThreadReplyerTemplate } from "./social-thread-replyer";
import { datingAppReplyer } from "./dating-app-replyer";
import { cateStudyTemplate } from "./case-study";
import { pressReleaseTemplate } from "./press-release";
import { faqSectionTemplate } from "./faq-section";
import { userGuideManualTemplate } from "./user-guide-manual-template";
import { courseCurriculumTemplate } from "./course-curriculum";

import { webinarScriptTemplate } from "./webinar-script";
import { interviewQuestionsTemplate } from "./interview-question";
import { surveyQuestionsTemplate } from "./survey-questions";
import { restaurantReviewTemplate } from "./restarurant-review";
import { travelItineraryTemplate } from "./travel-itinerary";
import { industryJargonListTemplate } from "./jargon-list";
import { termsAndConditionsTemplate } from "./terms";
import { clientProposalTemplate } from "./client-proposal";

import { researchSummaryTemplate } from "./research-summary";
import { privacyPolicyTemplate } from "./privacy";
import { realEstateListingTemplate } from "./real-estate-listing";
import { contentRepurposingIdeasTemplate } from "./content-repurpose-idea";
import { inspirationalQuotesTemplate } from "./inspirational-quotes";
import { competitorAnalysisTemplate } from "./competitor-analysis";
import { petitionTextTemplate } from "./petition-text";
import { eventInvitationTemplate } from "./event-invitation";
import { bookIdeaTemplate } from "./book-ideas";
import { noTemplate } from "./no-template";
import { wrapTemplates } from "./util";
import { facebookAdsTemplate } from "./facebook-ads";
import { uniqueContentIdeaTemplate } from "./unique-content-ideas";
import { summarizerTemplate } from "./summarizer";
import { clickBaitTemplate } from './click-bait';
const templates = wrapTemplates([
  customTemplate,
  blogTemplate,
  uniqueContentIdeaTemplate,
  summarizerTemplate,
  productReviewTemplate,
  termsAndConditionsTemplate,
  lyricTemplate,
  instagramPostTemplate,
  instagramBioTemplate,
  facebookPostTemplate,
  facebookAdsTemplate,
  twitterPostTemplate,
  productDescriptionTemplate,
  productLauncherTemplate,
  eventPromotionTemplate,
  customerTestimonialTemplate,
  specialDiscountPromotionTemplate,
  welcomeConfirmationEmailTemplate,
  jobDescriptionTemplate,
  aboutUsTemplate,
  landingPageTemplate,
  clickBaitTemplate,
  youtubeVideoScriptTemplate,
  tiktokVideoScriptTemplate,
  productPromotionTemplate,
  recurringEmailTemplate,
  shortStoryTemplate,
  reviewResponderTemplate,
  benefitStatementTemplate,
  betterExplainTemplate,
  coldEmailTemplate,
  socialThreadReplyerTemplate,
  datingAppReplyer,
  cateStudyTemplate,
  pressReleaseTemplate,
  faqSectionTemplate,
  userGuideManualTemplate,
  courseCurriculumTemplate,
  webinarScriptTemplate,
  interviewQuestionsTemplate,
  surveyQuestionsTemplate,
  restaurantReviewTemplate,
  travelItineraryTemplate,
  industryJargonListTemplate,
  clientProposalTemplate,
  researchSummaryTemplate,
  privacyPolicyTemplate,
  realEstateListingTemplate,
  contentRepurposingIdeasTemplate,
  inspirationalQuotesTemplate,
  competitorAnalysisTemplate,
  petitionTextTemplate,
  eventInvitationTemplate,
  bookIdeaTemplate,
  noTemplate,
]);

export const defaultTemplateId = customTemplate.id;
const templatesById = keyBy(templates, "id");
export {
  customTemplate,
  blogTemplate,
  uniqueContentIdeaTemplate,
  productReviewTemplate,
  summarizerTemplate,
  termsAndConditionsTemplate,
  lyricTemplate,
  instagramPostTemplate,
  instagramBioTemplate,
  facebookPostTemplate,
  facebookAdsTemplate,
  twitterPostTemplate,
  productDescriptionTemplate,
  productLauncherTemplate,
  templates,
  templatesById,
  noTemplate,
  eventPromotionTemplate,
  customerTestimonialTemplate,
  specialDiscountPromotionTemplate,
  welcomeConfirmationEmailTemplate,
  jobDescriptionTemplate,
  aboutUsTemplate,
  landingPageTemplate,
  youtubeVideoScriptTemplate,
  tiktokVideoScriptTemplate,
  productPromotionTemplate,
  recurringEmailTemplate,
  shortStoryTemplate,
  reviewResponderTemplate,
  benefitStatementTemplate,
  betterExplainTemplate,
  coldEmailTemplate,
  socialThreadReplyerTemplate,
  datingAppReplyer,
  cateStudyTemplate,
  pressReleaseTemplate,
  faqSectionTemplate,
  userGuideManualTemplate,
  courseCurriculumTemplate,
  webinarScriptTemplate,
  interviewQuestionsTemplate,
  surveyQuestionsTemplate,
  restaurantReviewTemplate,
  travelItineraryTemplate,
  industryJargonListTemplate,
  clientProposalTemplate,
  researchSummaryTemplate,
  privacyPolicyTemplate,
  realEstateListingTemplate,
  contentRepurposingIdeasTemplate,
  inspirationalQuotesTemplate,
  competitorAnalysisTemplate,
  petitionTextTemplate,
  eventInvitationTemplate,
  bookIdeaTemplate,
};

export default templates;
