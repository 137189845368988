import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  isKeywordsDefined,
  isPartialContentDefined,
  isTitleDefined,
} from "../validation";

export const tiktokVideoScriptTemplate: TemplateType = {
  id: "tiktok-video-script-251dy",
  value: "tiktok-video-script-251dy",
  label: "tiktok-video-script",
  slug: "tiktok-video-script",
  categories: [
    templateCategory.socialMedia,
    templateCategory.video,
    templateCategory.entertainment,
  ],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "tiktok Video Script",
  title: "TikTok Video Script",
  metaTitle: "Free TikTok Video Script Writer",
  metaDescription:
    "Boost your TikTok presence with our free video script writer. Create engaging, viral content effortlessly and skyrocket your TikTok fame.",
  defaultWordsCount: 350,
  contentTypeLabel: "tiktok Video Script",
  helpText: "Choose a topic and style for your TikTok video script",
  defaultCollectionName: "Tiktok Video Script collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your Tiktok Video Script",
  defaultPostName: "Untitled Tiktok Video Script",
  toGeneratorPrompt: "Need to generate more TikTok video scripts?",
  cardDescription:
    "Craft compelling content that engages, entertains, and grows your follower count.",
  shortDescription: "Specify a topic and style for your TikTok video script",
  caption: "Create captivating scripts for your TikTok videos in seconds.",
  requiredFields: ["title"],
  addtionalFields: [],
  excludedFields: [
    "keywords",
    "writingStyle",
    "editorialImage",
    "startFromOutline",
  ],
  fields: {
    title: {
      label: "Video Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Eg: 10 Tips to Boost Your Productivity",
      onValidate: (post) => {
        return !!(
          isDescriptionDefined(post) ||
          isPartialContentDefined(post) ||
          isKeywordsDefined(post)
        );
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a compelling title for tiktok video";

        if (description) {
          prompt += ` with the topic of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (toneOfVoice) {
          prompt += `, in a ${toneOfVoice} style`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the script: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "What's your video about?",
      fieldName: "description",
      required: true,
      placeholder: `Example: A tips-and-tricks video on how to boost your productivity using GenerateForMe.com's writing tools`,
      description: " ",
    },
    toneOfVoice: {
      label: "Video Style",
      fieldName: "toneOfVoice",
      placeholder: "Eg: humorous, informative etc",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isTitleDefined(post));
      },
      calculateCost: (config) => config.wordsCount || 350,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const toneOfVoice = post.toneOfVoice;
        const title = post.title;
        const targetAudience = post.targetAudience;
        const keywords = post.keywords;

        let prompt =
          "Write a script for a TikTok video based on the provided information";
        if (description) {
          prompt += `Some info on the video: ${description}`;
        }

        if (toneOfVoice) {
          prompt += `, style of the video: ${toneOfVoice}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience:: ${targetAudience}`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The script should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". Please ensure the content is engaging and make sure to include a caption and intro, outro as well.";

        return prompt;
      },
    },
  },
};
