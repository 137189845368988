import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";
const createdTemplate = templateCreator({
  value: "content repurposing ideas",
  categories: [templateCategory.blog],
  defaultWordsCount: 400,
  titleName: "title",
});

export const contentRepurposingIdeasTemplate = {
  ...createdTemplate,
  contentType: "content repurposing ideas",
  contentTypeLabel: "content repurposing ideas",
  metaTitle: "Free Content Repurposing Ideas Generator",
  metaDescription:
    "Boost your content strategy with our free Content Repurposing Ideas Generator. Optimize and diversify your content for maximum engagement!",
  helpText:
    "Provide the original content's details, including its format, topic, and target audience",
  cardDescription:
    "Repurpose & breathe new life into old content, maximizing reach and SEO potential.",
  shortDescription:
    "Create content repurposing ideas based on the provided original content details",
  caption:
    "Maximize your content's potential by repurposing it into various formats and reaching new audiences",
  excludedFields: [
    "writingStyle",
    "toneOfVoice",
    "editorialImage",
    "startFromOutline",
  ],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: `Enter or generate a title for your content repurposing ideas`,
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable title for content repurposing ideas based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      required: true,
      description: " ",
      placeholder: `- [Original Content]\n- [New Format] Example: Blog post to infographic\n- [New Platform] Example: Facebook, Twitter and Instagram`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;

        let prompt =
          "Generate content repurposing ideas for the following original content";

        if (description) {
          prompt += `Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should be appealing to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
