import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "press release",
  categories: [
    templateCategory.marketing,
    templateCategory.socialMedia,
    templateCategory.custom,
  ],
  defaultWordsCount: 500,
  titleName: "title",
});

export const pressReleaseTemplate = {
  ...createdTemplate,
  contentType: "press release",
  contentTypeLabel: "press release",
  metaTitle: "Free Press Release Generator",
  metaDescription:
    "Boost your brand's visibility with our free Press Release Generator. Craft compelling, SEO-optimized press releases effortlessly.",
  helpText:
    "Provide the key details and context for the press release to generate a professional and attention-grabbing document",
  cardDescription:
    "Craft compelling narratives and reach a wider audience without spending a dime.",
  shortDescription:
    "Create a polished press release based on the provided information",
  caption:
    "Announce your news or event with a well-crafted press release to garner media attention",
  excludedFields: ["toneOfVoice", "editorialImage", "startFromOutline"],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: `Enter or generate a title for your press release`,
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable title for a press release based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Background information",
      fieldName: "description",
      required: true,
      description: " ",
      placeholder: `- [What is the press release about?]
- [Additional Details]`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const writingStyle = post.writingStyle;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;

        let prompt =
          "Generate a professional and attention-grabbing press release based on the following information";

        if (description) {
          prompt += `. Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }
        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words.`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
