import { templateCategory, TemplateType } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

export const socialThreadReplyerTemplate: TemplateType = {
  id: "social-replyer-PmeQS",
  value: "social-replyer-PmeQS",
  label: "social-replyer",
  slug: "social-replyer",
  categories: [templateCategory.socialMedia],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "social media thread reply",
  title: "Social Media Thread Reply",
  defaultWordsCount: 100,
  contentTypeLabel: "social media thread reply",
  metaTitle: "Free Social Media Thread Replyer",
  metaDescription:
    "Streamline your social media engagement with our free Social Media Thread Replyer. Enhance interactions and foster community growth effortlessly.",
  helpText:
    "Provide the original post content and any additional context to generate a relevant and engaging reply",
  defaultCollectionName: "Social Thread Replies",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your reply",
  defaultPostName: "Untitled Reply",
  toGeneratorPrompt: "Need to generate more social media thread replies?",
  cardDescription:
    "Automate responses and build an active online community effortlessly.",
  shortDescription:
    "Craft a personalized response to a social media post, whether it's for personal or business purposes",
  caption:
    "Join in on social media conversations and make your voice heard with well-crafted replies",
  addtionalFields: [],
  excludedFields: [
    "targetAudience",
    "keywords",
    "writingStyle",
    "editorialImage",
    "startFromOutline",
  ],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title for your reply",
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isPartialContentDefined(post));
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a title for a reply to a social media post";

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content from the reply: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "Details",
      fieldName: "description",
      required: true,
      placeholder: `- [Original post content]
- [Purpose of the reply]`,
      description:
        "Enter the details of the original post you're responding to",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Friendly, formal",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 100,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;

        let prompt =
          "Craft a reply to a social media post based on the provided information";

        if (description) {
          prompt += `. Details: ${description}`;
        }

        if (toneOfVoice) {
          prompt += ` using a ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words.`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
