import { templateCategory } from "../types";

import {
  isDescriptionDefined,
  createOnInvalidFunc,
  isPartialContentDefined,
  isTitleDefined,
} from "../validation";

export const recurringEmailTemplate = {
  id: "recurring-email-email",
  value: "recurring-email-email",
  label: "Recurring Email Newsletter",
  slug: "recurring-email-email",
  categories: [templateCategory.email, templateCategory.marketing],
  tags: ["Welcome", "Confirmation", "Email", "Onboarding", "Customer"],
  contentType: "newsletter",
  title: "Recurring Email Newsletter",
  contentTypeLabel: "newsletter",
  metaTitle: "Free Newsletter Generator",
  metaDescription:
    "Create engaging newsletters effortlessly with our free generator. Boost your email marketing strategy and keep your audience captivated!",
  defaultWordsCount: 400,
  helpText:
    "Provide the newsletter title, a brief description of the contents, and any updates or promotions to include",
  defaultCollectionName: "Recurring email newsletters",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your recurring email newsletter",
  defaultPostName: "Untitled Newsletter",
  toGeneratorPrompt: "Need to generate more email newsletters?",
  cardDescription:
    "Enhance your email marketing strategy and engage your audience like never before.",
  shortDescription:
    "Try to provide more details to create a well-crafted recurring email newsletter",
  caption:
    "You can edit and customize the generated content to better suit your specific audience and platform.",
  requiredFields: [],
  excludedFields: [
    "keywords",
    "writingStyle",
    "editorialImage",
    "startFromOutline",
  ],
  fields: {
    title: {
      label: "Newsletter Title",
      fieldName: "title",
      placeholder: "Enter or generate a title for your newsletter",
      calculateCost: () => 50,
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isPartialContentDefined(post));
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        let prompt = "Generate a captivating title for a recurring newsletter";

        if (targetAudience) {
          prompt += `, targeting the audience: ${targetAudience}`;
        }

        if (description) {
          prompt += `. the description is: ${description}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Description",
      fieldName: "description",
      required: true,
      description: "Provide a brief description of the newsletter's contents",
      placeholder: `- [Topic] Example: Introducing a new AI tool - GenerateForMe.com\n- [Tip 1] Example: By utilizing GenerateForMe.com, you can create content for a global audience while staying on brand\n- [Tip 2] Example: Leverage GenerateForMe.com's blog post writer to generate long-form content that ranks on Google\n- [Call To Action] Example: Reply to this email to earn a 20% discount on your next purchase`,
    },
    targetAudience: {
      label: "Target Audience",
      fieldName: "targetAudience",
      optional: true,
      placeholder: "Eg: New Customers, First-time subscribers",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Excitement and Enthusiasm",
    },
    content: {
      label: "Content",
      fieldName: "content",
      onValidate: (post) => {
        return isDescriptionDefined(post) || isTitleDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: createOnInvalidFunc(),
      buildPrompt: (post) => {
        const title = post.title;
        const productName = post.description;
        const targetAudience = post.targetAudience;
        const wordsCount = post.wordsCount;
        const language = post.language;
        let prompt = "Compose a recurring email newsletter";

        if (productName) {
          prompt += `with the description of: "${productName}"`;
        }

        if (targetAudience) {
          prompt += `, targeting the audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        prompt += ".";

        if (wordsCount) {
          prompt += ` Limit the content to ${wordsCount} words.`;
        }

        return prompt;
      },
    },
  },
};
