import { truncateToMaxWords } from "client-server-shared/utils/text-utils";
import { templateCategory, TemplateFieldType, TemplateType } from "../types";
import { populateInfoBaseItem } from "../util";

import { isDescriptionDefined, isPurposeDefined } from "../validation";

export const twitterPostTemplate: TemplateType = {
  id: "twitter-post-251dy",
  value: "twitter-post-251dy",
  label: "twitter-post",
  slug: "twitter-post",
  categories: [templateCategory.socialMedia],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "twitter post",
  metaTitle: "Free Viral Tweet Generator",
  metaDescription:
    "Create viral tweets with our Twitter Post Generator. Feed past viral tweets to recreate and launch your own Twitter sensation.",
  title: "Twitter Post",
  defaultWordsCount: 50,
  contentTypeLabel: "twitter post",
  helpText: "Choose at least one post topic to begin",
  defaultCollectionName: "Twitter Post collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your Twitter Post",
  defaultPostName: "Untitled Twitter Post",
  toGeneratorPrompt: "Need to generate more twitter posts?",
  tokenCount: 1000,
  cardDescription: "Create viral tweets in seconds.",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  newStructure: true,
  freeLimit: 1,
  addtionalFields: [],
  excludedFields: [
    "writingStyle",
    "editorialImage",
    "startFromOutline",
    "keywords",
    "wordsCount",
  ],
  fields: {
    description: {
      label: "Post Topic",
      required: true,
      fieldName: "description",
      placeholder: `What's on your mind? Example: Sharing 5 marketing tips for solo entrepreneurs.`,
      description: " ",
    },
    background: {
      label: "Background",
      optional: true,
      fieldName: "background",
      info: "Could be any factual information, a story, or a personal experience.",
      placeholder: `Example: I've been a solo entrepreneur for 5 years and here are the top 10 things i learnt...`,
      type: TemplateFieldType.TextArea,
    },
    samples: {
      label: "Sample Tweets",
      optional: true,
      fieldName: "samples",
      wordsLimit: 1500,
      description: " ",
      placeholder: `Copy and paste viral tweets for replication or upload from infobase`,
      type: TemplateFieldType.TextArea,
    },
    additionalInstructions: {
      label: "Additional Instructions",
      optional: true,
      fieldName: "additionalInstructions",
      description: " ",
      placeholder: `Example: It should be structured as a list of twitter threads, incorporate open loops and promises in between.`,
      type: TemplateFieldType.TextArea,
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Conversational, Bold, Thought-provoking, Friendly, formal",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(isPurposeDefined(post) || isDescriptionDefined(post));
      },
      calculateCost: (config) => config.wordsCount || 250,
      onInValid: () => {},
      buildSystemPrompt: async (post) => {
        const sampleTweets = post.samples;
        const background = post.background;
        const language = post.language;
        const toneOfVoice = post.toneOfVoice;

        let prompt = `You're an expert in helping users create engaging and potentially viral tweets based on the information they provided.`;

        if (sampleTweets) {
          prompt += `Here are the partial content of some of the tweets that you have written in the past that yielded great results, feel free to replicate some of its styles: "${truncateToMaxWords(
            sampleTweets,
            1500
          )}"\n`;
        }

        if (background) {
          prompt += `Here are some background information about the tweet you're about to create: "${truncateToMaxWords(
            background,
            1000
          )}"\n`;
        }

        if (language && language !== "English") {
          prompt += `You will write the tweet in ${language} language.\n`;
        }
        if (toneOfVoice) {
          prompt += `You will write in the following tone of voice: "${toneOfVoice}".\n`;
        }
        prompt += `You will ensure each tweet or each threaded tweet is no more than 280 characters.\n`;

        return prompt;
      },
      buildPrompt: (post) => {
        const description = post.description;

        const additionalInstructions = post.additionalInstructions || "";
        let prompt = "";

        if (description) {
          prompt += `Description of the tweet: ${description}\n`;
        }

        if (additionalInstructions) {
          prompt += `Additional instruction: ${additionalInstructions}\n`;
        }

        prompt += `
Output:`;

        return prompt;
      },
    },
  },
};
