import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  isPartialContentDefined,
  isTitleDefined,
} from "../validation";

export const shortStoryTemplate: TemplateType = {
  id: "short-story-251dy",
  value: "short-story-251dy",
  label: "short-story",
  slug: "short-story",
  categories: [templateCategory.entertainment],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "short story",
  title: "Short Story",
  metaTitle: "Free Short Story Writer",
  metaDescription:
    "Experience the power of storytelling with our free short story writer. Unleash your creativity and craft compelling narratives effortlessly.",
  defaultWordsCount: 500,
  contentTypeLabel: "short story",
  helpText:
    "Provide a story title, main character, setting, and a brief summary of the plot",
  defaultCollectionName: "Short stories",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your short story",
  defaultPostName: "Untitled Story",
  toGeneratorPrompt: "Need to generate more short stories?",
  cardDescription:
    "Craft compelling narratives and captivate your readers effortlessly.",
  shortDescription:
    "Try to provide more details to create a well-crafted short story",
  caption:
    "You can edit and customize the generated content to better suit your specific audience and preferences.",
  addtionalFields: [],
  excludedFields: ["keywords", "editorialImage", "startFromOutline"],
  fields: {
    title: {
      label: "Story Title",
      fieldName: "title",
      calculateCost: () => 100,
      placeholder: "Enter or generate a story title",
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isPartialContentDefined(post));
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a compelling title for an short story";

        if (description) {
          prompt += ` with the description of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }
        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the story: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "Story Description",
      fieldName: "description",
      required: true,
      placeholder: `Example: An astronaut stranded on a desolate planet must find a way to survive and make contact with Earth before his oxygen runs out.`,
      description: " ",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Friendly, formal",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(isTitleDefined(post) || isDescriptionDefined(post));
      },
      calculateCost: (config) => config.wordsCount || 250,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const writingStyle = post.writingStyle;
        const targetAudience = post.targetAudience;

        const wordsCount = post.wordsCount;
        const language = post.language;

        let prompt = "Write a short story based on the provided information";

        if (description) {
          prompt += `. Story description: ${description}`;
        }

        if (toneOfVoice) {
          prompt += ` using a ${toneOfVoice} tone of voice`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The story should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". Please ensure the content is well-structured and engaging.";

        return prompt;
      },
    },
  },
};
