import { keywordsField } from "../common-fields";
import { templateCategory, TemplateFieldType, TemplateType } from "../types";

import {
  canGeneratePostContent,
  isDescriptionDefined,
  createOnInvalidFunc,
  isKeywordsDefined,
  isPartialContentDefined,
} from "../validation";

export const lyricTemplate: TemplateType = {
  id: "lyric-TeHqM",
  value: "lyric-TeHqM",
  label: "Lyric",
  slug: "lyric",
  categories: [templateCategory.entertainment],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "lyric",
  title: "Lyric Generator",
  contentTypeLabel: "lyric",
  metaTitle: "Free Lyric Writer",
  metaDescription:
    "Unleash your creativity with our Free Lyric Writer. Craft compelling song lyrics effortlessly and express your musical ideas like a pro.",
  helpText: "Choose at least one keyword, description, or song name to begin",
  defaultCollectionName: "Lyric collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a name for your lyric",
  defaultPostName: "Untitled Lyric",
  toGeneratorPrompt: "Need to generate more lyrics?",
  defaultWordsCount: 250,
  tokenCount: 300,
  freeLimit: 3,
  newStructure: true,
  cardDescription: "Craft Your Perfect Song Today with AI.",
  shortDescription:
    "Simply fill in the blanks below with your desired keywords and let the AI do the rest",
  caption: "",
  requiredFields: ["title"],
  excludedFields: ["editorialImage", "startFromOutline"],
  fields: {
    description: {
      label: "Description",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder:
        "Example: a song about falling in love with myself, overcoming fear",
    },
    ...keywordsField,
    targetAudience: {
      label: "Inspiration",
      fieldName: "targetAudience",
      optional: true,
      placeholder: "Example: a mixed Leonard Cohen and Bob Dylan vibe",
    },
    samples: {
      label: "Sample Lyric",
      fieldName: "samples",
      optional: true,
      info: "This could be lyric you have written in the past or your favorite song lyric",
      type: TemplateFieldType.TextArea,
      placeholder: "Enter some sample lyrics that you would like to replicate",
    },
    writingStyle: {
      label: "Genre",
      fieldName: "writingStyle",
      optional: true,
      placeholder: "pop, rock, country, hip-hop, etc",
      autoCompleteSuggestions: ["Rap", "Pop", "Rock"].map((v) => ({
        label: v,
        value: v,
      })),
    },
    toneOfVoice: {
      label: "Theme",
      fieldName: "toneOfVoice",
      autoCompleteSuggestions: [
        "Lighthearted",
        "Serious",
        "Nostalgic",
        "Love",
      ].map((v) => ({ label: v, value: v })),
      placeholder: "Example: loving",
      optional: true,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return canGeneratePostContent(post);
      },
      calculateCost: (config) => config.wordsCount || 250,
      onInValid: createOnInvalidFunc(
        "Either a song name or some description is required to generate lyric"
      ),
      buildSystemPrompt: (post) => {
        let prompt = `You are a song lyric writer, you help artists write song lyrics based on the details they provide. You will use the provided details to craft an unforgettable musical composition.`;
        const language = post.language;
        const toneOfVoice = post.toneOfVoice;

        const sampleLyric = post.samples;

        if (sampleLyric) {
          prompt += `Here are some sample lyrics you have written in the past that yielded great results: "${sampleLyric}"\n`;
        }

        if (toneOfVoice) {
          prompt += `You will write in the following theme: "${toneOfVoice}"\n`;
        }

        if (language && language !== "English") {
          prompt += `You will write in ${language} language\n`;
        }
        prompt += `Your written song will include verses, chorus, bridge with chords and melody.`;
        return prompt;
      },
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const keywords = post.keywords;
        let prompt = "";

        if (description) {
          prompt += `Description of the song: ${description} \n`;
        }

        if (targetAudience) {
          prompt += `. Inspiration of the song: ${targetAudience} \n`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `Incorporating the following keywords: ${keywords.join(
            ", "
          )}\n`;
        }

        prompt += `
Output:`;

        return prompt;
      },
    },
  },
};
