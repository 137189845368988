import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  createOnInvalidFunc,
  isPartialContentDefined,
  isTitleDefined,
} from "../validation";

export const welcomeConfirmationEmailTemplate = {
  id: "welcome-confirmation-email",
  value: "welcome-confirmation-email",
  label: "Welcome/Confirmation Email",
  slug: "welcome-confirmation-email",
  categories: [templateCategory.email, templateCategory.marketing],
  tags: ["Welcome", "Confirmation", "Email", "Onboarding", "Customer"],
  contentType: "email",
  title: "Welcome Email",
  contentTypeLabel: "email",
  metaTitle: "Free Welcome Email Generator",
  metaDescription:
    "Boost your subscriber engagement with our free Welcome Email Generator. Create compelling welcome emails effortlessly to kickstart customer relationships.",
  defaultWordsCount: 300,
  helpText:
    "Create a welcome or confirmation email for onboarding new customers or subscribers",
  defaultCollectionName: "Welcome/Confirmation Email Collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a subject for your email",
  defaultPostName: "Untitled Email",
  toGeneratorPrompt: "Need to generate more Welcome/Confirmation Emails?",
  cardDescription:
    "Create personalized, engaging welcome emails in just a few clicks.",
  shortDescription: "All fields are <strong>optional</strong>",
  requiredFields: [],
  excludedFields: [
    "keywords",
    "writingStyle",
    "editorialImage",
    "startFromOutline",
  ],
  fields: {
    title: {
      label: "Subject",
      fieldName: "title",
      placeholder: "Enter or generate a subject for your email",
      calculateCost: () => 50,
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isPartialContentDefined(post));
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        let prompt =
          "Generate a captivating subject for a welcome/confirmation email";

        if (targetAudience) {
          prompt += `, targeting the audience: ${targetAudience}`;
        }

        if (description) {
          prompt += `. the description is: ${description}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Background information",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder: `- [Product/Service description]
- [What to expect next]`,
    },
    targetAudience: {
      label: "Target Audience",
      fieldName: "targetAudience",
      optional: true,
      placeholder: "Eg: New Customers, First-time subscribers",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Excitement and Enthusiasm, Urgency and Scarcity",
    },
    content: {
      label: "Email Content",
      fieldName: "content",
      onValidate: (post) => {
        return isDescriptionDefined(post) || isTitleDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 200,
      onInValid: createOnInvalidFunc(
        "A subject or some partial content is required generate welcome/confirmation email content"
      ),
      buildPrompt: (post) => {
        const title = post.title;
        const productName = post.description;
        const targetAudience = post.targetAudience;
        const wordsCount = post.wordsCount;
        const language = post.language;
        let prompt =
          "Create a Welcome or Confirmation Email for a product/service";

        if (productName) {
          prompt += `with the description of: "${productName}"`;
        }

        if (targetAudience) {
          prompt += `, targeting the audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        prompt += ".";

        if (wordsCount) {
          prompt += ` Limit the content to ${wordsCount} words.`;
        }

        return prompt;
      },
    },
  },
};
