import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "event invitation",
  categories: [templateCategory.email, templateCategory.custom],
  defaultWordsCount: 200,
  titleName: "title",
});

export const eventInvitationTemplate = {
  ...createdTemplate,
  contentType: "event invitation",
  contentTypeLabel: "event invitation",
  metaTitle: "Free Event Invitation Generator",
  metaDescription:
    "Create stunning event invitations in minutes with our free generator. Perfect for any occasion. Impress your guests and boost attendance now!",
  helpText:
    "Provide the details of your event to create an invitation that will make your audience excited to attend",
  cardDescription:
    "Customize, download, and send invites that will impress your guests.",
  shortDescription:
    "Create an invitation that will make your audience excited to attend",
  caption:
    "Make sure your audience is excited to attend your event with a personalized and engaging invitation",
  excludedFields: ["editorialImage", "startFromOutline"],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: `Enter a title for your event invitation`,
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const details = post.description;
        const language = post.language;

        let prompt =
          "Provide a suitable title for an event invitation based on the following details:";

        if (details) {
          prompt += ` details is ${details}`;
        }
        const content = post.content;

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder: `- What is the event about? Example: celebrate the launch of GenerateForMe.com \n- What can the attendees expect from the event? Example: Free food, free high tech gadgets and opportunity to work for GenerateForMe.com \n- Who are the speakers?\n- Any special announcements for the event?`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 50,
      onInValid: () => {},
      buildPrompt: (post) => {
        const details = post.description;
        const language = post.language;
        const writingStyle = post.writingStyle;
        const wordsCount = post.wordsCount;
        const targetAudience = post.targetAudience;
        const title = post.title;

        let prompt =
          "Create a personalized and engaging invitation for your event based on the following details:";

        if (details) {
          prompt += ` details is ${details}`;
        }
        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, reply in ${writingStyle} format`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
