import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  createOnInvalidFunc,
  isPartialContentDefined,
  isTitleDefined,
} from "../validation";

export const aboutUsTemplate: TemplateType = {
  id: "about-us-251dy",
  value: "about-us-251dy",
  label: "about-us",
  slug: "about-us",
  categories: [
    templateCategory.socialMedia,
    templateCategory.marketing,
    templateCategory.websiteCopy,
    templateCategory.ecommerce,
  ],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "about us section",
  metaTitle: "Free About Us Writer",
  metaDescription:
    "Generate a compelling About Us page for free with our AI writer. Create an impactful first impression and connect better with your audience.",
  title: "About Us Bio",
  defaultWordsCount: 800,
  contentTypeLabel: "about us section",
  helpText: "Craft an informative and engaging About Us section",
  defaultCollectionName: "About Us collection",
  collectionNamePlaceholder: "Enter a name for your About Us collection",
  postNamePlaceholder: "Enter your company/team name",
  defaultPostName: "Untitled About Us",
  cardDescription:
    "Craft compelling stories and connect with your audience.",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  excludedFields: ["editorialImage", "startFromOutline"],
  fields: {
    title: {
      label: "Company/Team Name",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter a company name or team name",
      onValidate: (post) => {
        return false;
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a compelling title for an about us";

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }
        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the about us: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Description",
      fieldName: "description",
      required: true,
      placeholder: `Give a short description of what your company/team offers and your background story`,
      description: " ",
    },
    targetAudience: {
      label: "Target Audience",
      optional: true,
      fieldName: "targetAudience",
      placeholder: "Stakeholders, Job Seekers etc",
    },
    content: {
      label: "About Us Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(isTitleDefined(post) || isDescriptionDefined(post));
      },
      calculateCost: (config) => config.wordsCount || 800,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const title = post.title;
        const targetAudience = post.targetAudience;

        let prompt =
          "Write an engaging About Us section based on the provided information";

        if (description) {
          prompt += `. some background info: ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (toneOfVoice) {
          prompt += ` using a ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The about us should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". Please ensure the content is well-structured and engaging.";

        return prompt;
      },
    },
  },
};
